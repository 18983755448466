import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2016-10-29",
  "title": "Before Searching for that Perfect Boilerplate",
  "description": "Project boilerplates might not be what you need",
  "cover": "/img/cover/newspaper.jpg",
  "pic": "/img/newspaper.jpg",
  "color": "#edcca9"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`You've got a brand new project. A clean slate. Another chance to prove to the world that not all software projects have to degenerate to mush. This one will be different. It will unshakably follow the standards and best practices from the brightest minds in the community. So you start out by googling for the perfect boilerplate. They all look pretty similar. Luckily you have the guiding Github stars to let you know which one to pick. You follow the instructions on the `}<inlineCode parentName="p">{`readme`}</inlineCode>{` and voila! New project all setup! You couldn't feel more productive. But before you type `}<inlineCode parentName="p">{`git add -A && git commit -m "initial commit"`}</inlineCode>{` let me tell you how this is going to play out.`}</p>
    <p>{`You'll take a look at all the new files in your repo. That productive feeling will start to fade, replaced by a growing sense of being lost – a feeling that usually comes from looking at a legacy codebase. `}<strong parentName="p">{`Your project doesn't have a single feature yet and it's no longer greenfield`}</strong>{`.`}</p>
    <p>{`But you'll power through this red flag because you trust the sources that recommend the boilerplate. You'll reference its `}<inlineCode parentName="p">{`readme`}</inlineCode>{` often, wanting to do everything the way the tool intends you to. You'll make sure all files and folders are organized as prescribed. You'll look past the bloat and complexity it added to your codebase. You trust that the productivity gains of using this thing will far outweigh the cognitive friction it keeps causing you.`}</p>
    <p>{`But it probably won't work out well. How can it? You don't understand most of what's going on in your own project. And even if you did, `}<a parentName="p" {...{
        "href": "/post/when-tech-makes-you-feel-dumb/"
      }}>{`you don't have 90% of the problems`}</a>{` this thing is solving. That boilerplate made a thousand decisions for you that may or may not be good for the actual problems you'll encounter as you build your product.`}</p>
    <p>{`The word `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Boilerplate_(text)#Etymology"
      }}>{`boilerplate`}</a>{` comes from the newspaper industry. Way back in the early 1900's, a local newspaper could purchase a thin sheet of steel pre-stamped with ads and stories from one of the larger national newspaper companies. This "boilerplate" would provide plenty of quick filler content, leaving some gaps in the layout for original content. This tool definitely helped the newspaper "ship" papers quickly. While some people began to frown upon the practice and see it as a sign of lower quality content, the productivity for the newspaper company was undeniable.`}</p>
    <p>{`I don't think this practice translates very well to software development. These newspapers were throwaway items. Meant to be read for a few days in a comfy chair and then crinkled up and used to start the wood stove fire. Not something to be maintained and expanded for years like your project will be.`}</p>
    <p>{`In a software project `}<strong parentName="p">{`poor decisions made early on are an order of magnitude more expensive than poor decisions made later on`}</strong>{`. These early choices have a way of sticking around `}<em parentName="p">{`indefinitely`}</em>{`. Features and abstractions and tools and documentation get written on top of them. Using a boilerplate is letting someone else make a bunch of decisions about your project's foundation, right from the get go.`}</p>
    <p>{`Just as a note, I really like `}<a parentName="p" {...{
        "href": "https://github.com/facebookincubator/create-react-app"
      }}>{`create-react-app`}</a>{` and don't consider it to be a boilerplate unless you `}<inlineCode parentName="p">{`eject`}</inlineCode>{` right away. More on that later.`}</p>
    <p>{`My advice is to ditch the boilerplate. You don't need it. Keep your project greenfield as long as you can. Avoid solving problems you don't have yet. Work hard to keep the code clean, simple, and understandable (more on this in a future post). If you want to enjoy working on your project long-term, don't outsource this important task to a boilerplate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      